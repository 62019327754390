// Generated by Framer (9bf39e5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["PRKGfRebs"];

const variantClassNames = {PRKGfRebs: "framer-v-e1ernx"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "PRKGfRebs", image: FYxTR_4z6, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "PRKGfRebs", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-3aoXR", classNames)} style={{display: "contents"}}>
<Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 751, intrinsicWidth: 767, pixelHeight: 751, pixelWidth: 767, ...toResponsiveImage(FYxTR_4z6)}} className={cx("framer-e1ernx", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"PRKGfRebs"} ref={ref} style={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, ...style}} transition={transition}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-3aoXR [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-3aoXR * { box-sizing: border-box; }", ".framer-3aoXR .framer-1odpwt { display: block; }", ".framer-3aoXR .framer-e1ernx { height: 712px; overflow: visible; position: relative; width: 608px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 712
 * @framerIntrinsicWidth 608
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"FYxTR_4z6":"image"}
 */
const FramerTtdVDjRIm: React.ComponentType<Props> = withCSS(Component, css, "framer-3aoXR") as typeof Component;
export default FramerTtdVDjRIm;

FramerTtdVDjRIm.displayName = "Hero Image";

FramerTtdVDjRIm.defaultProps = {height: 712, width: 608};

addPropertyControls(FramerTtdVDjRIm, {FYxTR_4z6: {title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerTtdVDjRIm, [])